<template>
  <div v-loading="contentLoading">
    <div style="margin-left: 15px;margin-right: 15px;padding-bottom: 30px" v-html="sysConfig.paramKeyValue"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        contentLoading: false,
        sysConfig: {
          id: 0,
          paramKey: "",
          paramKeyValue: "",
        },
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        this.contentLoading = true;
        this.$http.get("sysConfig/policyKey").then(res => {
          this.sysConfig = res.data;
          this.contentLoading = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
    },
  };
</script>

<style scoped>

</style>
